import React, { useState, useEffect } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import HeaderSEO from '../../components/partials/HeaderSEO'
import { StaticImage, GatsbyImage } from 'gatsby-plugin-image'
import { v4 as uuidv4 } from 'uuid'
import Layout from '../../components/Layout'
import Spacing from '../../components/partials/Spacing'

import { navbarScrolling } from '../../helpers/navbarScrolling'

interface StateProps {
  department: DepartmentProps
}
interface DepartmentProps {
  activeDepartment: 'founder' | 'engineer' | 'marketing' | 'sales' | 'people'
}

const aboutUs: React.FC<StateProps> = () => {
  const data = useStaticQuery(graphql`
    query AboutUsSEO {
      allContentfulPages(
        filter: { pageReference: { pageIdentifier: { eq: "Company_About" } } }
      ) {
        edges {
          node {
            title {
              title
            }
            keywords {
              keywords
            }
            description {
              description
            }
            oGimage {
              fluid(quality: 100) {
                src
              }
            }
          }
        }
      }
      allContentfulLensior {
        edges {
          node {
            image {
              title
              gatsbyImageData(layout: FULL_WIDTH, width: 160, height: 160)
              description
            }
            linkedin
            name
            jobTitle
            department
            description {
              description
            }
          }
        }
      }
      allContentfulPressItem(
        sort: { order: DESC, fields: updatedAt }
        limit: 3
      ) {
        edges {
          node {
            title
            link
            image {
              title
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  `)

  const pageSEO = data.allContentfulPages.edges[0].node
  const teamMembers = data.allContentfulLensior.edges
  const pressItems = data.allContentfulPressItem.edges

  const [department, setDepartment] = useState<DepartmentProps>({
    activeDepartment: 'founder',
  })

  const { activeDepartment } = department

  useEffect(() => {
    navbarScrolling()
  }, [])

  const onClick = (e) => {
    setDepartment({ activeDepartment: e.target.id })
  }

  return (
    <Layout linkColor={'dark'} isTransparent={true} hasBanner={true}>
      <HeaderSEO pageSEO={pageSEO} />

      <section>
        <div className="about-us-hero d-flex flex-wrap align-items-center">
          <div className="container-about-us bg-white py-5">
            <h1 className="text-center f-48 ">We are Lenses-Ey-Oh!</h1>
            <p className="f-20 pt-4 pb-2 text-center">
              The people behind Lenses, a complete developer experience for
              building event-driven applications on Apache Kafka.
            </p>
          </div>
        </div>
        <div className="about-us-hero-image "></div>
      </section>

      <div className="d-none d-xl-block">
        <Spacing />
      </div>
      <div className="d-block d-xl-none">
        <div className="my-5 mobile-margin-reset"></div>
      </div>

      <section>
        <div className="container-1">
          <div className="d-flex flex-wrap justify-content-center">
            <div className="col-9 text-center mobile-text-start">
              <p className="f-20">
                We’re a group of data engineers, developers, industry experts,
                designers, data analysts, writers and open-source contributors.
                We’re on a mission to democratize real-time data for engineers,
                making sure this data can be applied by operational leaders to
                drive their businesses forward.
              </p>

              <p className="f-20">
                That’s why we are developing Lenses - to help teams simplify
                their operations on technologies like <b> Apache Kafka</b> and{' '}
                <b> Kubernetes</b>. Then run their business intelligently in
                real-time.
              </p>

              <div className="roll-button mt-5 mobile-text-center">
                <a
                  id="about-us-discover-lenses"
                  className="text-white  bg-red"
                  href="/usecases/"
                >
                  <span data-hover="Discover Lenses">Discover Lenses</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Spacing />
      {/* FOUNDING STORY */}
      <section className="mobile-margin-top">
        <div className="container-1">
          <h2 className="text-center f-32 fw-900">How it started</h2>
          <div className="d-flex flex-wrap mobile-padding-reset mobile-margin-bottom justify-content-between align-items-center">
            <div className="col-md-6 align-self-center">
              <h3 className="f-28">
                Reducing the complexity of <br /> real-time
              </h3>
              <p className="lh-1-5">
                Since the dawn of time (well, nearly) there has been one
                constant:‌ businesses want to accelerate innovation and achieve
                operational excellence.
              </p>
              <p className="lh-1-5">
                But data can often hold us back from doing so. Our founding
                fathers and mothers, Antonios, Christina, Andrew and Stefan, saw
                how teams struggled to be productive on the latest data
                technologies: lack of data access and the steep learning curve
                required for stream processing held them back.
              </p>
              <p>
                In 2016, they built{' '}
                <span className="primary-text"> Lenses </span> to solve this
                challenge.
              </p>
              <p className="lh-1-5">
                Our Founders became some of the biggest contributors to the
                Apache Kafka and Kubernetes ecosystems, and Lenses continues to
                be loved by hundreds of customers.
              </p>
            </div>
            <div className="col-md-5">
              <StaticImage
                src="../../resources/images/about/how-it-started.png"
                placeholder="tracedSVG"
                alt="One step at a time"
              />
            </div>
          </div>
        </div>

        <Spacing />

        <div className="container-1">
          <h2 className="text-center f-32 fw-900">How it&apos;s going</h2>
          <div className="d-flex flex-wrap flex-column-reverse flex-sm-row pb-5 justify-content-between align-items-center">
            <div className="col-md-5">
              <StaticImage
                src="../../resources/images/about/how-its-going.png"
                className="img-fluid"
                placeholder="tracedSVG"
                alt="Solving Software"
              />
            </div>

            <div className="col-md-6">
              <h3 className="f-28">
                Executing on a universe of real-time data
              </h3>
              <p className="lh-1-5">
                In 2021, Lenses.io joined forces with{' '}
                <b>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.celonis.com/"
                  >
                    Celonis
                  </a>
                </b>
              </p>
              <p className="lh-1-5">
                We continue to pour our energy and focus into Lenses as the
                industry&apos;s leading Developer Experience for building
                event-driven applications on Apache Kafka, knowing that this
                data will power our business execution.
              </p>
              <p>The journey continues.</p>
            </div>
          </div>
        </div>
      </section>

      <Spacing />
      <section>
        <div className="container-1">
          <h2 className="text-center">The Team</h2>

          <div className="d-sm-flex flex-wrap d-block text-center justify-content-center">
            <div
              id="founder"
              className={`mx-4 f-18 fw-400 department d-block d-md-inline 
                ${
                  activeDepartment === 'founder'
                    ? 'department-active'
                    : 'department-inactive'
                }`}
              onClick={(e) => onClick(e)}
            >
              Leadership
            </div>
            {/* START OF GUILLAUME COMMENTS
             <div
              id="engineer"
              className={`f-18 fw-400 mx-4 department d-block d-md-inline
                ${
                  activeDepartment === 'engineer'
                    ? 'department-active'
                    : 'department-inactive'
                }`}
              onClick={(e) => onClick(e)}
            >
             Board Members
            </div>
            <div
              id="marketing"
              className={`f-18 fw-400 mx-4 department d-block d-md-inline
                ${
                  activeDepartment === 'marketing'
                    ? 'department-active'
                    : 'department-inactive'
                }`}
              onClick={(e) => onClick(e)}
            >
              Marketing
            </div>

            <div
              id="sales"
              className={`f-18 fw-400 mx-4 department d-block d-md-inline
                ${
                  activeDepartment === 'sales'
                    ? 'department-active'
                    : 'department-inactive'
                }`}
              onClick={(e) => onClick(e)}
            >
              Sales
            </div>

            <div
              id="people"
              className={`f-18 fw-400 mx-4 department d-block d-md-inline
                ${
                  activeDepartment === 'people'
                    ? 'department-active'
                    : 'department-inactive'
                }`}
              onClick={(e) => onClick(e)}
            >
              People’s Department
            </div>
*/}
          </div>
          <div
            className="d-flex flex-wrap justify-content-around mobile-padding-reset mobile-margin-reset"
            id="lenses-people"
          >
            {teamMembers.map((memberNode, index) => {
              let member = memberNode.node
              if (activeDepartment === member.department) {
                return (
                  <div
                    key={`${member.image.title}_${index}`}
                    className={`mx-2 ${member.department} my-5 mobile-margin-top`}
                  >
                    <div className="image-container">
                      <div className="image-container-overlay" />
                      <GatsbyImage
                        className="w-100 person-image"
                        image={member.image.gatsbyImageData}
                        alt={member.image.title}
                        loading="eager"
                      />
                      {member.linkedin && (
                        <span className="about-linkedIn mb-2 pb-1">
                          <a
                            href={member.linkedin}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <StaticImage
                              className="w-50"
                              alt={member.image.title}
                              src="../../resources/images/about/linkedin.png"
                              placeholder="tracedSVG"
                            />
                          </a>
                        </span>
                      )}
                    </div>
                    <div
                      className={`text-center h2-like mx-auto f-19 mt-3 lensior-name ${
                        member.name.length > 25
                          ? 'w-100'
                          : member.name.length < 14
                          ? 'w-50'
                          : ''
                      }`}
                    >
                      {member.name}
                    </div>
                    <div className="mx-auto text-center">{`${member.jobTitle}`}</div>
                  </div>
                )
              }
            })}
            {/* End For Here ^ */}
          </div>
        </div>
      </section>
      {/* OFFICES */}
      <section className="pt-5 pb-4 px-5 mobile-padding-reset mobile-margin-reset">
        <div className="container px-5 mobile-padding-reset">
          <div className="row mobile-margin-reset">
            <div className="col-sm-12 col-md-8 offset-md-2 text-center">
              <div className="heading-title mt-5 mb-0">
                <h2 className="f-32 fw-900">Find us here</h2>
              </div>
            </div>
          </div>
          <div className="row mx-auto">
            <div className="col-12">
              <StaticImage
                placeholder="tracedSVG"
                className="w-100"
                src="../../resources/images/careers/map.svg"
                alt="map"
              />
            </div>
          </div>
        </div>
      </section>

      <Spacing />
      {/* <!-- PRESS --> */}
      <section>
        <div className="container-1 mobile-margin-top">
          <div className="text-center">
            <h2 className="f-32 fw-900">In the press</h2>
          </div>
          <div className="d-flex flex-wrap mt-3">
            {pressItems.map((pressItem) => {
              return (
                <div className="col-md col-sm-6 col-12 mt-1" key={uuidv4()}>
                  <div className="home-three-items-box border-top-0 rounded-1">
                    <GatsbyImage
                      className="w-100 px-3 mt-2 rounded-top-1"
                      image={pressItem.node.image.gatsbyImageData}
                      alt={pressItem.node.image.title}
                    />
                    <div className="py-4 px-3">
                      <p className="h4-like mb-1">Newsroom</p>
                      <p className="cera-pro mt-4">{pressItem.node.title}</p>
                      <a
                        className="primary-text home-link-box"
                        href={pressItem.node.link}
                      >
                        Read more <i className="red-pointer-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
          <div className="text-center mt-5">
            <div className="roll-button">
              <a
                id="about-us-discover-lenses"
                className="text-white  bg-red"
                href="/company/newsroom/"
              >
                <span data-hover="Visit Newsroom">Visit Newsroom</span>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="mobile-margin-bottom mobile-margin-top my-5">
        <div className="container-1 bg-dark rounded-1 p-5">
          <div className="d-flex flex-wrap">
            <div className="col-md-12 col-12 text-center roll-button">
              <p className="mb-5 paragraph-title text-white">
                Do you want to learn more about lenses.io?
              </p>
              <a className="bg-white primary-text" href="/blog/">
                <span data-hover="Read our blog"> Read our blog</span>
              </a>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
export default aboutUs
